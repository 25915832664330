import 'popper.js';
import React, { useEffect } from 'react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import '../assets/css/responsive.css';
import '../assets/css/style.css';
import '../assets/elagent-icon/style.css';
import '../assets/etline-icon/style.css';
import '../assets/flaticon/flaticon.css';
import Layout from '../components/layout';
import Seo from '../components/seo';
import '../components/style/_developer.scss';
import logo4 from '../images/logo4.png';
import './index.scss';

const Tos = () => {
  useEffect(() => {
    if (window) {
      window.location.replace(
        'https://www.miculaprozar.ro/termeni-si-conditii'
      );
    }
  }, []);

  return (
    <Layout>
      <Seo title="Termeni si Condiții" />
      <div className="container">
        <div className="row align-items-center mt-4">
          <div className="col-lg-12">
            <img src={logo4} style={{ width: '170px' }}></img>
          </div>
        </div>
        <div className="row align-items-center mt-2 justify-center">
          <div className="col-lg-10">
            <h1 className="text-center">Termeni și Condiții</h1>
          </div>
        </div>
        <div className="row align-items-center mt-4">
          <div className="col-lg-12">
            {/* <ReactMarkdown source={markdown} /> */}
            Dumă spre{' '}
            <a href="https://www.miculaprozar.ro/termeni-si-conditii">
              Termeni și Condiții
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Tos;
